<template>
	<div>
		<a-spin :spinning="loading" tip="logining..">
			<div style="height: 100vh; width: 100%;"></div>
		</a-spin>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				loading:false
			}
		},
		created(){
			// let shop_token =  this.$route.query.shop_token;
			// console.log(shop_token);
			// if(!shop_token){
			// 	console.log(1111111)
			// 	return false;
			// 	this.$router.push('/login');
			// }else{

				// this.$http.papi('platform/authLogin',{
				// 	token:shop_token
				// }).then(res=>{
				// 	console.log(res);
				// 	localStorage.setItem('housekeeping-token',shop_token);
				// 	this.$router.push('/index');
				// }).catch(res=>{
				//
				// });
		//	}
		},
		methods:{

		}
	}
</script>

<style>

</style>
